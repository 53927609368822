import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: black;
`
const Main = styled.main`
  height: 100%;
  width: 100%;
`

const Layout = ({ children }) => {
  return (
    <Container>
      <Main>{children}</Main>
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
