/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ lang, meta }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            twitterAuthor
            myImage
          }
        }
      }
    `
  )
  console.log(`------------------ site ${JSON.stringify(site, null, 2)}`)

  const {
    siteMetadata: { author, title, description, twitterAuthor, url, myImage },
  } = site
  const metaDescription = description
  const metaTitle = title

  const TwitterMeta = [
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:image`,
      content: `https://${myImage}`,
    },
    {
      name: `twitter:creator`,
      content: twitterAuthor,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:text:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: description,
    },
    {
      name: `twitter:creator`,
      content: twitterAuthor,
    },
    {
      name: `twitter:site`,
      content: url,
    },
  ]
  const DefaultMeta = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      name: "url",
      content: url,
    },
    {
      name: "author",
      content: author,
    },
    {
      name: "title",
      property: `og:title`,
      content: metaTitle,
    },
    {
      name: "description",
      property: `og:description`,
      content: metaDescription,
    },
    {
      name: "image",
      property: `og:image:secure`,
      content: `https://${myImage}`,
    },
    {
      name: "image",
      property: `og:image`,
      content: `http://${myImage}`,
    },
    {
      name: "image",
      property: `og:image:url`,
      content: `http://${myImage}`,
    },
    {
      name: "image",
      property: `og:image:type`,
      content: `image/jpg`,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:image:width`,
      content: `1200`,
    },
    {
      property: `og:image:height`,
      content: `630`,
    },
  ]
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={title ? `%s | ${description}` : null}
      meta={DefaultMeta.concat(meta).concat(TwitterMeta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
